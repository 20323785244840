import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ApiProvider } from "./contexts/ApiContext";
import { UiProvider } from "./contexts/UiContext";

ReactDOM.render(
  <React.StrictMode>
    <ApiProvider>
      <UiProvider>
        <App />
      </UiProvider>
    </ApiProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
