import { useState, memo, useEffect } from "react";

import { useApi } from "../../contexts/ApiContext";
import FamilyTreeCluster from "./FamilyTreeCluster";
import "./FamilyTree.css";

/**
 * Component that renders the tree for a specified person in the user's family tree.
 *
 * Renders all the disconnected "clusters" of nodes side-by-side and maintains each cluster's currently
 * selected node. Places in a row at the bottom all the single nodes that have no relations, to keep them
 * separate.
 *
 * @param {{personId: string, onSelect: Function}} props component properties object
 * @param props.personId id of the person to render the tree for
 * @param props.onSelect callback for when a person in the tree is selected, with signature
 *                        (node: node object reprenting selected node, edit: boolean representing
 *                        whether the node was selected for editing)
 */
const FamilyTree = ({ personId, onSelect }) => {
  const api = useApi();
  const [clusterRows, setClusterRows] = useState([]);

  useEffect(() => {
    const newClusterRows = [[]];
    const currentClusters = clusterRows.flat().sort((a, b) => (a.index > b.index ? 1 : -1));
    api
      .getClusterRoots()
      .map((id, index) => {
        if (api.getContainingClusterIndex(personId) == index) {
          return { selectedPerson: personId, index: index };
        } else if (
          currentClusters.length > index &&
          api.getContainingClusterIndex(currentClusters[index].selectedPerson) == index
        ) {
          return currentClusters[index];
        } else {
          return { selectedPerson: id, index: index };
        }
      })
      .forEach((cluster) => {
        if (api.getClusterSize(cluster.index) > 1) {
          newClusterRows[0].push(cluster);
        } else {
          if (newClusterRows.length == 1) newClusterRows.push([]);
          newClusterRows[1].push(cluster);
        }
      });
    setClusterRows(newClusterRows);
  }, [api.getClusterRoots().length, personId]);

  return (
    <div className="family-tree">
      {clusterRows.map((clusters, index) => {
        return (
          <>
            <div
              className="family-tree-cluster-row"
              key={"family-tree-cluster-row" + index}
              data-testid={"family-tree-cluster-row" + index}
            >
              {clusters.map((cluster, clusterIndex) => {
                return (
                  <FamilyTreeCluster
                    personId={cluster.selectedPerson}
                    key={"family-tree-cluster-row" + index + "-" + clusterIndex}
                    active={cluster.selectedPerson == personId}
                    onSelect={onSelect}
                  />
                );
              })}
            </div>
            <br />
          </>
        );
      })}
    </div>
  );
};

export default memo(FamilyTree, (prevProps, nextProps) => {
  if (prevProps.personId == nextProps.personId) {
    return true;
  }
  return false;
});
