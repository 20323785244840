import React, { useState, useEffect } from "react";

import { useApi } from "../../contexts/ApiContext";
import FamilyTreeUnionLine from "./FamilyTreeUnionLine";
import FamilyTreeChildLine from "./FamilyTreeChildLine";
import "./FamilyTreeLines.css";

/**
 * Component that displays all the necessary lines between the nodes in a cluster.
 *
 * @param {{nodes: Array, width: number, height: number, style: Object}} props component properties object
 * @param props.nodes array of node objects containing id and position data
 * @param props.width should match width of the cluster
 * @param props.height should match height of the cluster
 * @param props.style CSS style object, currently supporting "opacity" and "transition"
 */
const FamilyTreeLines = ({ nodes, width, height, style }) => {
  const api = useApi();

  const getUnionLines = () => {
    const unions = [];
    const alreadyAdded = [];
    let colorVariation = 1;
    const totalUnions = [];
    nodes.forEach((node) => {
      api.getSpouses(node.id).forEach((spouseId) => {
        const spouse = getNode(spouseId);
        if (
          spouse &&
          !alreadyAdded.includes(node.id + "-" + spouse.id) &&
          !alreadyAdded.includes(spouse.id + "-" + node.id)
        ) {
          if (!totalUnions[node.id]) totalUnions[node.id] = 0;
          if (!totalUnions[spouse.id]) totalUnions[spouse.id] = 0;
          totalUnions[node.id]++;
          totalUnions[spouse.id]++;

          alreadyAdded.push(node.id + "-" + spouseId);
          unions.push({
            node1: node,
            node2: spouse,
            colorVariation: colorVariation,
            offset: getOffset(totalUnions[node.id], totalUnions[spouse.id]),
          });
          colorVariation++;
        }
      });
    });
    return unions;
  };

  const getOffset = (spouseCount1, spouseCount2) => {
    const mostSpouses = spouseCount1 > spouseCount2 ? spouseCount1 : spouseCount2;

    return mostSpouses % 2 == 0
      ? -Math.ceil((mostSpouses - 1) / 2) * 10
      : Math.ceil((mostSpouses - 1) / 2) * 10;
  };

  const getChildNodes = (parent1, parent2) => {
    return api
      .getChildren(parent1.id, parent2.id)
      .map((childId) => getNode(childId))
      .filter((child) => child);
  };

  const getNode = (id) => {
    return nodes.filter((node) => node.id == id)[0];
  };

  return (
    <svg
      className="family-tree-lines"
      style={{
        width: width + 40,
        height: height + 40,
        opacity: style.opacity,
        transition: style.transition,
      }}
    >
      {getUnionLines().map((unionLine, unionIndex) => {
        return (
          <>
            <FamilyTreeUnionLine {...unionLine} key={"family-tree-line" + unionIndex} />
            {getChildNodes(unionLine.node1, unionLine.node2).map((child, childIndex) => {
              return (
                <FamilyTreeChildLine
                  key={"family-tree-line" + unionIndex + "-" + childIndex}
                  parent1={unionLine.node1}
                  parent2={unionLine.node2}
                  child={child}
                  colorVariation={unionLine.colorVariation}
                  offset={unionLine.offset}
                />
              );
            })}
          </>
        );
      })}
    </svg>
  );
};

export default FamilyTreeLines;
