import React, { useState, useEffect } from "react";

import DnaAnalysisResultDetails from "./DnaAnalysisResultDetails";
import "./DnaAnalysisResults.css";
import DnaAnalysisResultStrength from "./DnaAnalysisResultStrength";

/**
 * Component that displays list of analysis results.
 *
 * @param {{results: Array}} props component properties object
 * @param props.results array of DNA analysis result objects
 */
const DnaAnalysisResults = ({ results }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const handleWindowResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <div className="dna-analysis-results">
      {results.length > 0 ? (
        screenWidth > 800 ? (
          <table>
            <tbody>
              <tr>
                <th className="id"></th>
                <th className="scenario">Possibilities</th>
                <th className="strength">Strength</th>
                <th className="outliers">Outliers</th>
              </tr>
              {results.map((result, index) => {
                return (
                  <tr
                    key={"result" + index}
                    data-testid={"result" + index}
                    className={`dna-analysis-result`}
                  >
                    <td className="id">{index + 1}</td>
                    <td className="scenario">
                      <DnaAnalysisResultDetails result={result} index={index} />
                    </td>
                    <td className="strength" title={result.strength * 100}>
                      <span style={{ width: 35, display: "inline-block" }}>
                        {Math.round(result.strength * 100)}
                      </span>
                      <DnaAnalysisResultStrength strength={result.strength} />
                    </td>
                    <td className="outliers">{result.insights.outliers.length}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <table>
            <tbody>
              {results.map((result, index) => {
                return (
                  <tr
                    key={"mobile-result" + index}
                    data-testid={"mobile-result" + index}
                    className={`dna-analysis-result`}
                  >
                    <td className="id">{index + 1}</td>
                    <td style={{ padding: 0 }}>
                      <table>
                        <tbody>
                          <tr>
                            <td className="scenario">
                              <strong style={{ display: "block", marginBottom: 10 }}>
                                Scenario:
                              </strong>
                              <DnaAnalysisResultDetails result={result} index={index} />
                            </td>
                          </tr>
                          <tr>
                            <td className="strength">
                              <strong>Strength: </strong>
                              <span style={{ width: 35, display: "inline-block" }}>
                                {Math.round(result.strength * 100)}
                              </span>
                              <DnaAnalysisResultStrength strength={result.strength} />
                            </td>
                          </tr>
                          <tr>
                            <td className="outliers">
                              <strong>Outliers: </strong>
                              {result.insights.outliers.length}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )
      ) : (
        <div className="dna-analysis-results-message">
          Click the Analyze button to generate a list of the most likely paternity/maternity
          scenarios.
        </div>
      )}
    </div>
  );
};

export default DnaAnalysisResults;
