import React, { useState, useEffect } from "react";

import DemoButton from "../DemoButton/DemoButton";
import logo from "../../assets/logo.svg";
import { useApi } from "../../contexts/ApiContext";
import "./HeaderPanel.css";
import xIcon from "../../assets/x.svg";
import { useUi } from "../../contexts/UiContext";
import burgerIcon from "../../assets/burger.svg";

/**
 * Header bar component contain logo and menu.
 */
const HeaderPanel = () => {
  const api = useApi();
  const ui = useUi();

  return (
    <header className="top-bar">
      <img className="top-bar-logo" src={logo} />
      <div className="main-menu" style={{ display: ui.mobileMenuOpen ? "block" : "" }}>
        <button
          className="button link-button"
          onClick={() => {
            ui.hideMobileMenu();
            window.open("privacy.html", "_blank");
          }}
        >
          Privacy
        </button>
        <button
          className="button link-button"
          onClick={() => {
            ui.hideMobileMenu();
            window.open("mailto:support@mysteryfam.com", "_blank");
          }}
        >
          Support
        </button>
        {!api.isAuthenticated ? (
          <>
            <button
              className="login-logout button link-button"
              onClick={() => api.login({ screen_hint: "signup" })}
            >
              Sign Up
            </button>
            <button className="login-logout button link-button" onClick={() => api.login()}>
              Login
            </button>
          </>
        ) : (
          <button
            className="login-logout button link-button"
            onClick={() => api.logout({ returnTo: window.location.origin })}
          >
            Log Out
          </button>
        )}
        {!api.isAuthenticated && !api.isDemoMode ? (
          <DemoButton onClick={() => ui.hideMobileMenu()} />
        ) : null}
        <button className="mobile-menu-close" onClick={() => ui.hideMobileMenu()}>
          <img src={xIcon} />
        </button>
      </div>
      <button className="burger" onClick={() => ui.showMobileMenu()}>
        <img src={burgerIcon} />
      </button>
    </header>
  );
};

export default HeaderPanel;
