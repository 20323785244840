import React, { useState, useEffect } from "react";

/**
 * Line component that represents a union between two nodes.
 *
 * @param {{node1: string, node2: string, colorVariation: number, offset: number}} props component properties object
 * @param props.node1 id of the first node
 * @param props.node2 id of the second node
 * @param props.colorVariation number from 1 to 15 to determine color of line, to differentiate overlapping lines
 * @param props.offset distance in pixels to offset line, to differentiate overlapping lines
 */
const FamilyTreeUnionLine = ({ node1, node2, colorVariation, offset }) => {
  const getLine = () => {
    if (node1.x > node2.x) {
      const tmp = node2;
      node2 = node1;
      node1 = tmp;
    }

    return {
      x1: node1.x + 20,
      y1: node1.y + offset + 20,
      x2: node2.x + 20,
      y2: node2.y + offset + 20,
      className: "union-" + colorVariation,
    };
  };

  return (
    <>
      <line {...getLine()} />
    </>
  );
};

export default FamilyTreeUnionLine;
