import { useEffect, useState } from "react";
import { useApi } from "../contexts/ApiContext";
import config from "../config.json";
import useNodeMatrix from "./useNodeMatrix";

/**
 * Hook that generates everything needed for laying out the nodes in the family tree for a given
 * person. Generates an array of node objects containing their screen positions (node.x, node.y)
 * as well as all data that should be displayed on the tree (node.id, node.name, node.sharedDna).
 *
 * Also returns width and height of the layout containing the provided nodes.
 *
 * @param {string} personId id of person to create node layout for
 * @param {Function} onLayoutChange called when layout changes with signature (newNodes, newWidth, newHeight)
 * @returns {{nodes: Array, width: number, height: number}} array of node objects, width, and height of layout
 * containing the provided nodes.
 */
const useNodeLayout = (personId, onLayoutChange) => {
  const api = useApi();
  const [nodes, setNodes] = useState([]);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const { matrix, relatedMatrices } = useNodeMatrix(personId);

  useEffect(() => {
    updateLayout(true);
  }, [matrix]);

  useEffect(() => {
    updateLayout();
  }, [api.getCacheVersion()]);

  const updateLayout = (notify = false) => {
    const newNodes = createNodes();
    const newWidth = calculateWidth();
    const newHeight = calculateHeight();
    if (onLayoutChange && notify) onLayoutChange(newNodes, newWidth, newHeight);
    setNodes(newNodes);
    setWidth(newWidth);
    setHeight(newHeight);
  };

  const createNodes = () => {
    return matrix
      .map((row, r) => {
        const rowWidth = getRowWidth(r, matrix);
        let currentRowNode = 0;
        return row
          .map((grouping, g) => {
            return grouping.map((personId, p) => {
              currentRowNode++;
              return {
                id: personId,
                name: api.getName(personId),
                sharedDna: api.getSharedDna(personId),
                x:
                  (currentRowNode - 1) * config.nodeSpacing +
                  g * config.nodeGroupSpacing +
                  (calculateWidth() - rowWidth) / 2,
                y: r * config.nodeRowSpacing,
                expandable: checkNodeExpands(personId),
              };
            });
          })
          .flat();
      })
      .flat();
  };

  const checkNodeExpands = (nodeId) => {
    if (nodeId == personId) return false;
    if (!relatedMatrices[nodeId]) {
      return false;
    }

    const currentNodes = matrix.flat(2);
    const potentialNodes = relatedMatrices[nodeId].flat(2);

    return potentialNodes.filter((id) => !currentNodes.includes(id)).length > 0;
  };

  const getRowWidth = (row) => {
    return (
      (matrix[row].length - 1) * config.nodeGroupSpacing +
      ([].concat(...matrix[row]).length - 1) * config.nodeSpacing
    );
  };

  const calculateWidth = () => {
    let longest = 0;
    matrix.forEach((row, r) => {
      const width = getRowWidth(r, matrix);
      if (width > longest) longest = width;
    });
    return longest;
  };

  const calculateHeight = () => {
    return (matrix.length - 1) * config.nodeRowSpacing;
  };

  return { nodes: nodes, width: width, height: height };
};

export default useNodeLayout;
