import React, { useState, setState, useEffect } from "react";
import PersonSearchForm from "../PersonSearchForm/PersonSearchForm";

/**
 * Modal window component for finding a person within the tree.
 *
 * @param {{onSubmit: Function, onCancel: Function}} props component properties object
 * @param props.onSubmit callback for form submit with signature (id: number representing id of found node)
 * @param props.onCancel callback for form cancel
 */
const FindPersonModal = ({ onSubmit, onCancel }) => {
  const cancel = () => {
    if (onCancel) onCancel();
  };

  return (
    <>
      <div className="dim" onClick={cancel}></div>
      <div className="find-person modal" style={{ width: 400 }}>
        <h2>Find Person</h2>
        <PersonSearchForm
          onSelect={(id) => {
            onSubmit(id);
          }}
        />
        <button className="button" onClick={cancel}>
          Cancel
        </button>
      </div>
    </>
  );
};

export default FindPersonModal;
