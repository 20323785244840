import React, { useEffect } from "react";
import CookieNotice from "@mirzalikic/react-cookie-notice";
import ReactGA from "react-ga4";

import "./App.css";
import { useApi } from "./contexts/ApiContext";
import { useUi } from "./contexts/UiContext";
import EditPersonPanel from "./components/EditPersonPanel/EditPersonPanel";
import FamilyTreePanel from "./components/FamilyTreePanel/FamilyTreePanel";
import DnaAnalysisPanel from "./components/DnaAnalysisPanel/DnaAnalysisPanel";
import HeaderPanel from "./components/HeaderPanel/HeaderPanel";
import WelcomePanel from "./components/WelcomePanel/WelcomePanel";
import LoadingModal from "./components/LoadingModal/LoadingModal";
import Tutorial from "./components/Tutorial/Tutorial";

function App() {
  const api = useApi();
  const ui = useUi();
  const { isAuthenticated } = useApi();

  useEffect(() => {
    ReactGA.initialize("G-LTTMW66P14");
    ReactGA.send("pageview");
  }, []);

  return (
    <div className="App">
      {ui.loadingMessage != null ? <LoadingModal message={ui.loadingMessage} /> : null}
      <HeaderPanel />
      <main>
        {isAuthenticated || api.isDemoMode ? (
          <>
            <FamilyTreePanel personId={ui.personId} />
            {ui.editPersonPanelOpen ? <EditPersonPanel personId={ui.personId} /> : null}
            <DnaAnalysisPanel />
          </>
        ) : (
          <WelcomePanel />
        )}
        {api.isDemoMode ? (
          <Tutorial
            step={ui.tutorialStep}
            onIncrement={() => ui.setTutorialStep(ui.tutorialStep + 1)}
          />
        ) : null}
      </main>
      <CookieNotice
        showCookiePreferences={false}
        containerStyle={{
          paddingTop: 20,
          paddingBottom: 20,
          fontSize: "0.8em",
          lineHeight: 1.2,
          boxShadow: "0 0 10px rgba(0,0,0,0.5)",
          color: "#fff",
          background: "rgb(36, 64, 31)",
          // background: "#fff",
          // color: "#1b1b1b",
        }}
        buttonPrimaryClass="button light"
        acceptAllButtonText="I Understand and Accept"
      >
        <h3 style={{ marginBottom: 10 }}>MysteryFam™ uses cookies</h3>
        <p style={{ marginTop: 10, marginBottom: 20 }}>
          By continuing to use this software, you consent to our use of cookies that both help it to
          function and provide useful analytics about how you interact with it.
        </p>
      </CookieNotice>
    </div>
  );
}

export default App;
