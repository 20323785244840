import React from "react";

import { useApi } from "../../contexts/ApiContext";
import DemoButton from "../DemoButton/DemoButton";
import "./WelcomePanel.css";

/**
 * Panel displaying welcome information and options when not logged in.
 */
const WelcomePanel = () => {
  const api = useApi();
  return (
    <div className="welcome-message">
      <h1>Searching for answers in your DNA matches?</h1>
      <h3 style={{ margin: "28px auto", maxWidth: 900 }}>
        <em>
          MysteryFam™ can help you find your birth parents (or grandparents) when you've received
          your DNA results but don't know what to do next.
        </em>
      </h3>
      <div className="welcome-panel-steps">
        <div>
          <p>
            <strong>
              <span className="step-tag">Step 1</span> Build Your Tree
            </strong>
            Using our purpose-built tools and guidance, you will begin to sort through your
            mysterious new relatives and work out their interrelationships. By the end you will have
            an interconnected family tree with only one missing piece—yourself!
          </p>
        </div>
        <div>
          <p>
            <strong>
              <span className="step-tag">Step 2</span> Get Instant Insights and Analysis
            </strong>
            As you work out the shape of your tree, MysteryFam™ will use the latest genetic
            algorithms and statistical data* to automatically suggest possible answers to your
            genetic mystery, helping you ultimately answer the question, "Where do I fit?"
          </p>
        </div>
      </div>
      <p>
        <em>
          * Data sourced from The Shared cM Project Version 4.0 by Blaine T. Bettinger, Ph.D., J.D.
        </em>
      </p>
      <div className="welcome-buttons">
        <DemoButton label="Get Started" />
        <button className="button ghost-button" onClick={() => api.login()}>
          Existing Users
        </button>
      </div>
    </div>
  );
};

export default WelcomePanel;
