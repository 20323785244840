import { Auth0Provider } from "@auth0/auth0-react";
import { ApiProvider } from "./ApiContext";

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
const origin = window.location.origin;

/**
 * Context provider that makes it easy to interact with a tree via the MysteryFam API.
 *
 * Provides all necessary functions for manipulating and gaining information about
 * the tree and the people in it, for executing DNA analysis, as well as for
 * authenticating with Auth0.
 */
const AuthWrapper = ({ children }) => {
  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={origin.includes("localhost") ? "http://localhost:3000" : origin}
      audience={audience}
    >
      <ApiProvider>{children}</ApiProvider>
    </Auth0Provider>
  );
};

export { AuthWrapper as ApiProvider };

export { useApi, ApiContext } from "./ApiContext";
