import React from "react";
import { useApi } from "../../contexts/ApiContext";
import { useUi } from "../../contexts/UiContext";
import ReactGA from "react-ga4";

/**
 * Button component for entering Demo Mode.
 *
 * @param {{ghostButton: boolean, label: string, onClick: Function}} props component properties object
 * @param props.ghostButton whether button will be displayed as a ghost button
 * @param props.label the label of the button
 * @param props.onClick callback for button click
 */
const DemoButton = ({ ghostButton, label, onClick }) => {
  const api = useApi();
  const ui = useUi();
  return (
    <button
      className={`demo-button button ${ghostButton ? "ghost-button" : ""}`}
      onClick={() => {
        ui.showLoadingModal("Setting up demo. Please wait...");
        api.enterDemoMode(() => {
          ui.hideLoadingModal();
        });
        ReactGA.initialize("G-LTTMW66P14");
        ReactGA.event({
          category: "User Engagement",
          action: "Entered Demo Mode",
        });
        if (onClick) onClick();
      }}
    >
      {label ?? "Demo Mode"}
    </button>
  );
};

export default DemoButton;
