import React, { useState } from "react";

import TutorialStep from "./TutorialStep";

/**
 * Tutorial overlay component that displays a certain TutorialStep component based on given
 * "step" property.
 *
 * @param {{step: number, onIncrement: Function}} props component properties object
 * @param props.step step number of TutorialStep to display
 * @param props.onIncrement callback that allows a step to signal that the tutorial should
 *                          proceed to the next step
 */
const Tutorial = ({ step, onIncrement }) => {
  const [finished, setFinished] = useState(false);

  return !finished ? (
    <>
      {step == 1 ? (
        <TutorialStep
          align="right"
          width={400}
          targetSelector="#dna-analysis-panel-analyze-button"
          distanceFromTarget={55}
          title="Try it Out!"
        >
          <p>
            The tree you see is an example of what you'll have after completing step one. It shows
            how all of your mysterious DNA matches are interrelated.
          </p>
          <p>
            <em>
              You can then use our automated statistical analysis to figure out where you're most
              likely to fit in that tree.
            </em>
          </p>
          <p>
            Click the <strong>Analyze</strong> button below.
          </p>
        </TutorialStep>
      ) : null}
      {step == 2 ? (
        <TutorialStep
          align="left"
          width={350}
          targetSelector=".dna-analysis-result .dna-analysis-result-details .expand-result"
          distanceFromTarget={25}
          title="Additional Insights"
        >
          <p>
            <em>
              The top result in this list is what we've determined to be the most likely scenario.
            </em>
          </p>
          <p>
            Click the <strong>plus</strong> button next to a result to expand it and view additional
            insights.
          </p>
        </TutorialStep>
      ) : null}
      {step == 3 ? (
        <TutorialStep
          align="left"
          width={350}
          targetSelector=".dna-analysis-result .dna-analysis-result-details .insights .hierarchy-line-horizontal"
          distanceFromTarget={25}
          title="Evidence and Outliers"
          hideable={false}
        >
          <p>
            Evidence that strengthens the case for this scenario is listed in <strong>green</strong>
            . Outliers that make this scenario unlikely are listed in{" "}
            <strong className="red">red</strong>.
          </p>
          <button className="button" onClick={() => onIncrement()}>
            Next
          </button>
        </TutorialStep>
      ) : null}
      {step == 4 ? (
        <TutorialStep
          align="center"
          width={350}
          targetSelector=".dna-analysis-result .strength"
          distanceFromTarget={25}
          title="Scenario Strength"
          hideable={false}
        >
          <p>
            The <strong>Strength</strong> field describes how well each scenario explains the data,
            based on statistical analysis.
          </p>
          <button className="button" onClick={() => onIncrement()}>
            Next
          </button>
        </TutorialStep>
      ) : null}
      {step == 5 ? (
        <TutorialStep
          align="left"
          width={350}
          targetSelector=".dna-analysis-panel-header .expand-panel"
          distanceFromTarget={25}
          title="DNA Analysis Panel"
        >
          <p>
            Click the <strong>collapse arrow</strong> to close the <strong>DNA Analysis</strong>{" "}
            panel.
          </p>
        </TutorialStep>
      ) : null}
      {step == 6 ? (
        <TutorialStep
          align="center"
          width={350}
          targetSelector=".family-tree-node.selected"
          distanceFromTarget={50}
          title="Select a Person"
        >
          <p>
            Click on a <strong>person</strong> to bring up the Edit Person panel.
          </p>
        </TutorialStep>
      ) : null}
      {step == 7 ? (
        <TutorialStep
          align="center"
          width={450}
          targetSelector=".App"
          distanceFromTarget={-150}
          title="You're Free to Play Around"
          showArrow={false}
          hideable={false}
        >
          <p>
            You're now free to play around with the family tree editor. This demo is a sandbox we've
            created just for you, so feel free to add, delete, and modify people.
          </p>
          <p>
            Try modifying the tree and clicking the <strong>Analyze</strong> button to see how it
            changes the results.
          </p>
          <p>
            <strong>Bonus:</strong> Try removing both of a person's parents to separate their branch
            of the tree into its own separate cluster.
          </p>
          <button className="button" onClick={() => setFinished(true)}>
            Got It
          </button>
        </TutorialStep>
      ) : null}
    </>
  ) : null;
};

export default Tutorial;
