import React, { useState, useEffect, useRef } from "react";

import { useUi } from "../../contexts/UiContext";
import "./DnaAnalysisResultDetails.css";
import expandResultIcon from "../../assets/expand-result.svg";
import collapseResultIcon from "../../assets/collapse-result.svg";

/**
 * Expandable details component that displays additional insights for an analysis result.
 *
 * @param {{result: Object}} props component properties object
 * @param props.result object containing DNA analysis results
 */
const DnaAnalysisResultDetails = ({ result }) => {
  const ui = useUi();
  const [expanded, setExpanded] = useState(false);
  const [hierarchyLineHeight, setHierarchyLineHeight] = useState(0);
  const insightsRef = useRef();

  const handleRedraw = () => {
    if (insightsRef.current) {
      const lastLine = insightsRef.current.querySelector(
        "li:last-child .hierarchy-line-horizontal"
      );
      setHierarchyLineHeight(lastLine.offsetTop + lastLine.parentNode.offsetTop - 20);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleRedraw);
    return () => window.removeEventListener("resize", handleRedraw);
  }, []);

  useEffect(() => {
    handleRedraw();
  });

  return (
    <div className="dna-analysis-result-details">
      {result.insights.evidence.length > 0 || result.insights.outliers.length > 0 ? (
        <button
          title={expanded ? "Collapse Result" : "Expand Result"}
          onClick={() => {
            setExpanded(!expanded);
            if (ui.tutorialStep == 2) ui.setTutorialStep(3);
          }}
          className="expand-result"
        >
          {expanded ? <img src={collapseResultIcon} /> : <img src={expandResultIcon} />}
        </button>
      ) : null}
      {expanded ? (
        <div className="hierarchy-line-vertical" style={{ height: hierarchyLineHeight }}></div>
      ) : null}
      <span className="description" dangerouslySetInnerHTML={{ __html: result.description }}></span>
      {expanded && (result.insights.evidence.length > 0 || result.insights.outliers.length > 0) ? (
        <ul ref={insightsRef} className="insights">
          {result.insights.evidence.map((evidence, index) => {
            return (
              <>
                <li key={"evidence" + index} className="evidence">
                  <div className="hierarchy-line-horizontal"></div>
                  <span
                    className="description"
                    dangerouslySetInnerHTML={{ __html: evidence.description }}
                  ></span>
                </li>
              </>
            );
          })}
          {result.insights.outliers.map((outlier, index) => {
            return (
              <>
                <li key={"outlier" + index} className="outlier">
                  <div className="hierarchy-line-horizontal"></div>
                  <span
                    className="description"
                    dangerouslySetInnerHTML={{ __html: outlier.description }}
                  ></span>
                </li>
              </>
            );
          })}
        </ul>
      ) : null}
    </div>
  );
};

export default DnaAnalysisResultDetails;
