import { memo, useEffect, useRef, useState } from "react";

import FamilyTreeNode from "./FamilyTreeNode";
import FamilyTreeLines from "./FamilyTreeLines";
import useNodeLayout from "../../hooks/useNodeLayout";
import "./FamilyTreeCluster.css";

/**
 * An individual cluster (or island) of interconnected nodes within a family tree.
 * A tree can have many of these.
 *
 * @param {{personId: string, active: boolean, onSelect: Function}} props component properties object
 * @param props.personId id of the selected node in the cluster
 * @param props.active whether or not this is the currently selected cluster
 * @param props.onSelect callback for when a node within this cluster is selected, with signature
 *                        (node: node object reprenting selected node, edit: boolean representing
 *                        whether the node was selected for editing)
 */
const FamilyTreeCluster = ({ personId, active, onSelect }) => {
  const ref = useRef(null);
  const [subOpacity, setSubOpacity] = useState(1);

  const { nodes, width, height } = useNodeLayout(personId, (newNodes, newWidth, newHeight) => {
    if (active) {
      const positionDelta = calculateSelectedPersonPositionDelta(newNodes);
      ref.current.style.transition = "none";
      setSubOpacity(0);
      ref.current.style.left = positionDelta ? positionDelta.x + "px" : 0;
      ref.current.style.top = positionDelta ? positionDelta.y + "px" : 0;
      if (!positionDelta) {
        ref.current.style.width = newWidth + "px";
        ref.current.style.height = newHeight + "px";
      }
      setTimeout(() => {
        ref.current.style.transition = "";
        ref.current.style.left = "0";
        ref.current.style.top = "0";
        ref.current.style.width = newWidth + "px";
        ref.current.style.height = newHeight + "px";
        setSubOpacity(1);
      }, 300);
    } else {
      ref.current.style.transition = "none";
      setSubOpacity(1);
      ref.current.style.width = newWidth + "px";
      ref.current.style.height = newHeight + "px";
    }
  });

  const calculateSelectedPersonPositionDelta = (newNodes) => {
    let positionDelta = false;
    const selectedNodeOld = nodes.filter((node) => node.id == personId)[0];
    const selectedNode = newNodes.filter((node) => node.id == personId)[0];
    if (selectedNode) {
      const selectedNodeNewX = selectedNode.x - width / 2;
      const selectedNodeNewY = selectedNode.y - height / 2;
      if (selectedNodeOld) {
        const selectedNodeOldX = selectedNodeOld.x - width / 2;
        const selectedNodeOldY = selectedNodeOld.y - height / 2;
        positionDelta = {
          x: selectedNodeOldX - selectedNodeNewX,
          y: selectedNodeOldY - selectedNodeNewY,
        };
      }
    }
    return positionDelta;
  };

  return (
    <div className={`family-tree-cluster${active ? " active" : ""}`} ref={ref}>
      <FamilyTreeLines
        nodes={nodes}
        width={width}
        height={height}
        style={{
          opacity: subOpacity,
          transition: subOpacity == 0 ? "none" : "",
        }}
      />
      {nodes.map((node) => {
        return (
          <FamilyTreeNode
            personId={node.id}
            key={"node-" + node.id}
            name={node.name}
            sharedDna={node.sharedDna}
            expandable={node.expandable}
            onClick={(personId, edit) => {
              onSelect(node, edit);
            }}
            selected={personId == node.id}
            x={node.x}
            y={node.y}
            style={{
              opacity: personId == node.id ? 1 : subOpacity,
              transition: subOpacity == 0 && personId != node.id ? "none" : "",
            }}
          />
        );
      })}
    </div>
  );
};

export default memo(FamilyTreeCluster, (prevProps, nextProps) => {
  if (prevProps.personId == nextProps.personId && prevProps.active == nextProps.active) {
    return true;
  }
  return false;
});
