import React, { useEffect } from "react";
import AddPersonModal from "../AddPersonModal/AddPersonModal";
import PersonField from "../PersonField/PersonField";
import "./PersonFieldRepeater.css";

/**
 * List of PersonField components. Items can be added or removed.
 *
 * A PersonFieldRepeater can be nested within itself. Each PersonField in the top-level
 * list will then have its own nested PersonFieldRepeater. This is useful for displaying
 * spouses and their children.
 *
 * @param {{title: string, people: array, onClick: Function, onDelete: Function, onAdd: Function, parentId: string, children: JSX.Element}} props component properties object
 * @param props.title type of person that the field will be used to select e.g. Spouse
 * @param props.people array of ids of the people to display in the repeater
 * @param props.onClick callback for when person clicked, with signature (personId: number, parentId?: number
 *                      representing id of parent if repeater is nested)
 * @param props.onDelete callback for when person removed, with signature (personId: number, parentId?: number
 *                       representing id of parent if repeater is nested)
 * @param props.onAdd callback for when person added, with signature (personId: number, parentId?: number
 *                    representing id of parent if repeater is nested)
 * @param props.parentId id of parent if repeater is nested
 */
const PersonFieldRepeater = ({ title, people, onClick, onDelete, onAdd, parentId, children }) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [newPeople, setNewPeople] = React.useState(people ? people : []);
  let oldPeople = [];

  people = !people ? [] : people;

  useEffect(() => {
    let arrayUpdated = false;
    if (oldPeople.length !== people.length) {
      arrayUpdated = true;
    } else if (oldPeople.length == 0 && people.length == 0) {
      arrayUpdated = true;
    } else {
      for (var i = 0; i < oldPeople.length; ++i) {
        if (oldPeople[i] !== people[i]) arrayUpdated = true;
      }
    }
    if (arrayUpdated) {
      oldPeople = [...people];
      setNewPeople(people ? [...people] : []);
    }
  }, [people]);

  return (
    <div className="person-field-repeater">
      {newPeople.map((personId) => {
        return (
          <PersonField
            key={personId}
            title={title}
            personId={personId}
            onDelete={(personId) => {
              setNewPeople(newPeople.filter((id) => id !== personId));
              if (onDelete) onDelete(personId, parentId ? parentId : null);
            }}
            onClick={(personId) => {
              if (onClick) onClick(personId, parentId ? parentId : null);
            }}
          >
            {children ? (
              <PersonFieldRepeater
                {...children.props}
                people={
                  typeof children.props.people == "function"
                    ? children.props.people(personId)
                    : children.props.people
                }
                parentId={personId}
              />
            ) : null}
          </PersonField>
        );
      })}
      <button className="add-person-button" onClick={() => setModalOpen(true)}>
        Add {title}
      </button>
      {modalOpen ? (
        <AddPersonModal
          title={title}
          onSubmit={(personId) => {
            setModalOpen(false);
            setNewPeople([...newPeople, personId]);
            if (onAdd) onAdd(personId, parentId ? parentId : null);
          }}
          onCancel={() => setModalOpen(false)}
        />
      ) : null}
    </div>
  );
};

export default PersonFieldRepeater;
