import React, { useEffect, useRef, useState } from "react";

import xIcon from "../../assets/x-white.svg";
import "./TutorialStep.css";

/**
 * Popup box component that displays tutorial text near a given target DOM element.
 * Content for the box is supplied by passing HTML between the opening/closing tags.
 *
 * @param {{align: string, title: string, width: number, targetSelector: string, showArrow: boolean, distanceFromTarget: number, hideable: boolean, children: JSX.Element}} props component properties object
 * @param props.align how to align box (and arrow) in relation to target DOM element.
 *                    Expects "left", "right", or "center".
 * @param props.title title of box to display in green bar at top
 * @param props.width how wide the box should be
 * @param props.targetSelector CSS selector that targets DOM element to attach box to
 * @param props.showArrow whether or not to show an arrow at the bottom that points a target element
 * @param props.distanceFromTarget how far above targeted element to render box
 * @param props.hideable whether the box can be closed without performing indicated action
 */
const TutorialStep = ({
  align,
  title,
  width,
  targetSelector,
  showArrow,
  distanceFromTarget,
  hideable,
  children,
}) => {
  const [left, setLeft] = useState(0);
  const [top, setTop] = useState(0);
  const [hidden, setHidden] = useState(false);
  const updateTimer = useRef(null);

  const updatePosition = () => {
    const target = document.querySelector(targetSelector);
    if (target) {
      const targetRect = target.getBoundingClientRect();
      setLeft(targetRect.left + targetRect.width / 2);
      setTop(targetRect.top + targetRect.height / 2);
    }
  };

  useEffect(() => {
    updatePosition();
    window.addEventListener("resize", updatePosition);
    updateTimer.current = setInterval(updatePosition, 300);

    return () => {
      window.removeEventListener("resize", updatePosition);
      clearInterval(updateTimer.current);
    };
  }, []);

  return !hidden ? (
    <div
      className={"tutorial-step align-" + align + (showArrow !== false ? " with-arrow" : "")}
      style={{ left: left, top: top }}
    >
      <div
        className="tutorial-window"
        style={{ width: width ?? 300, bottom: distanceFromTarget ?? 50 }}
      >
        <div className="tutorial-window-title">
          {title}
          {hideable !== false ? (
            <button className="tutorial-window-close" onClick={() => setHidden(true)}>
              <img src={xIcon} />
            </button>
          ) : null}
        </div>
        <div className="tutorial-window-content">{children}</div>
      </div>
    </div>
  ) : null;
};

export default TutorialStep;
