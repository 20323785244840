import React, { useState, useEffect } from "react";

import PersonField from "../PersonField/PersonField";
import PersonFieldRepeater from "../PersonFieldRepeater/PersonFieldRepeater";
import { useApi } from "../../contexts/ApiContext";
import PersonDetailsForm from "../PersonDetailsForm/PersonDetailsForm";
import { useUi } from "../../contexts/UiContext";
import "./EditPersonPanel.css";
import deleteIcon from "../../assets/trash.svg";
import xIcon from "../../assets/x.svg";

/**
 * Panel component that enables editing a specified person.
 *
 * @param {{personId: string}} props component properties object
 * @param props.personId id of the person to edit
 */
const EditPersonPanel = ({ personId }) => {
  const api = useApi();
  const ui = useUi();
  const [left, setLeft] = useState("-100%");

  useEffect(() => {
    setLeft("0");
  }, []);

  useEffect(() => {
    document.querySelector(".edit-person-window").scrollTo(0, 0);
  }, [personId]);

  const close = () => {
    ui.closeEditPersonPanel();
  };

  return (
    <div className="edit-person-window" style={{ left: left }}>
      <button className="edit-person-window-close" onClick={() => close()}>
        <img src={xIcon} />
      </button>
      <section>
        <h2>
          Edit Person
          <button
            className="edit-person-delete"
            title="Delete Person"
            onClick={() => {
              let newPerson = api.getAdjacentPerson(personId);
              ui.showLoadingModal("Deleting person...");
              api.deletePerson(personId, () => {
                if (newPerson) {
                  ui.setPersonId(newPerson);
                } else {
                  close();
                }
                ui.hideLoadingModal();
              });
            }}
          >
            <img src={deleteIcon} />
          </button>
        </h2>
        <h4 className="person-id">(ID: {personId})</h4>
        <PersonDetailsForm
          personId={personId}
          onChange={(data) => {
            api.setPersonDetails(personId, data.name, data.dateOfBirth, data.sharedDna);
          }}
        />
      </section>
      <section>
        <h3>Biological Parents</h3>
        <PersonField
          title="Parent"
          personId={api.getParent1(personId)}
          onChange={(parent1Id) => {
            api.setParent1(personId, parent1Id, () => ui.hideLoadingModal());
          }}
          onClick={(parent1Id) => {
            ui.setPersonId(parent1Id);
          }}
        />
        <PersonField
          title="Parent"
          personId={api.getParent2(personId)}
          onChange={(parent2Id) => {
            api.setParent2(personId, parent2Id, () => ui.hideLoadingModal());
          }}
          onClick={(parent2Id) => {
            ui.setPersonId(parent2Id);
          }}
        />
      </section>
      <section>
        <h3>Spouses &amp; Children</h3>
        <PersonFieldRepeater
          title="Spouse"
          people={api.getSpouses(personId)}
          onAdd={(spouseId) => {
            api.addSpouse(personId, spouseId, () => ui.hideLoadingModal());
          }}
          onDelete={(spouseId) => {
            api.removeSpouse(personId, spouseId, () => ui.hideLoadingModal());
          }}
          onClick={(spouseId) => {
            ui.setPersonId(spouseId);
          }}
        >
          <PersonFieldRepeater
            title="Child"
            people={(parentId) => {
              return api.getChildren(parentId, personId);
            }}
            onAdd={(childId, spouseId) => {
              api.addChild(personId, spouseId, childId, () => ui.hideLoadingModal());
            }}
            onDelete={(childId, spouseId) => {
              api.removeChild(personId, spouseId, childId, () => ui.hideLoadingModal());
            }}
            onClick={(childId) => {
              ui.setPersonId(childId);
            }}
          />
        </PersonFieldRepeater>
      </section>
    </div>
  );
};

export default EditPersonPanel;
