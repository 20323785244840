import { memo, useEffect, useRef, useState } from "react";
import { useUi } from "../../contexts/UiContext";

import "./FamilyTreeNode.css";

/**
 * Individual node component within a family tree cluster.
 *
 * @param {{personId: string, name: string, sharedDna: number, selected: boolean, x: number, y: number, style: Object, onClick: Function, expandable: boolean}} props component properties object
 * @param props.personId id of the person
 * @param name name of the person
 * @param sharedDna amount of DNA shared (in centimorgans)
 * @param selected whether the node is selected
 * @param x horizontal position of node within cluster
 * @param y vertical position of node within cluster
 * @param style CSS style object, currently supporting "opacity" and "transition"
 * @param onClick callback for when node is clicked, with signature
 *                 (personId: string id of clicked node, edit: boolean representing
 *                 whether the node was selected for editing)
 * @param expandable whether node can be expanded to reveal subnodes
 */
const FamilyTreeNode = ({
  personId,
  name,
  sharedDna,
  selected,
  x,
  y,
  style,
  onClick,
  expandable,
}) => {
  const ref = useRef(null);
  const ui = useUi();
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  return (
    <div
      id={"node-" + personId}
      className={
        "family-tree-node" + (selected ? " selected" : "") + (personId[0] == "s" ? " dummy" : "")
      }
      style={{
        left: x,
        top: y,
        opacity: style.opacity,
        transition: style.transition,
      }}
      onMouseDown={(e) => {
        setMousePosition({ x: e.screenX, y: e.screenY });
      }}
      onClick={(e) => {
        if (
          personId[0] != "s" &&
          Math.abs(e.screenX - mousePosition.x) < 2 &&
          Math.abs(e.screenY - mousePosition.y) < 2
        ) {
          if (ui.tutorialStep == 6) ui.setTutorialStep(7);
          if (onClick) onClick(personId, !e.target.classList.contains("expand-handle"));
        }
      }}
      ref={ref}
    >
      {name}
      {sharedDna != null && sharedDna > 0 ? (
        <div className="family-tree-node-shared-dna" data-testid="family-tree-node-shared-dna">
          {sharedDna} cM
        </div>
      ) : null}
      {expandable ? (
        <button className="expand-handle" data-testid="expand-handle">
          +
        </button>
      ) : null}
    </div>
  );
};

export default memo(FamilyTreeNode, (prevProps, nextProps) => {
  if (
    prevProps.personId == nextProps.personId &&
    prevProps.name == nextProps.name &&
    prevProps.sharedDna == nextProps.sharedDna &&
    prevProps.selected == nextProps.selected &&
    prevProps.x == nextProps.x &&
    prevProps.y == nextProps.y &&
    prevProps.expandable == nextProps.expandable &&
    prevProps.style.opacity == nextProps.style.opacity
  ) {
    return true;
  }
  return false;
});
