import React, { useState, useEffect } from "react";
import config from "../../config.json";

/**
 * Line component that connects given parents to a given child.
 *
 * @param {{parent1: string, parent2: string, child: string, colorVariation: number, offset: number}} props component properties object
 * @param props.parent1 id of the first parent
 * @param props.parent2 id of the second parent
 * @param props.child id of the child
 * @param props.colorVariation number from 1 to 15 to determine color of line, to differentiate overlapping lines
 * @param props.offset distance in pixels to offset line, to differentiate overlapping lines
 */
const FamilyTreeChildLine = ({ parent1, parent2, child, colorVariation, offset }) => {
  const getLineParts = () => [
    {
      x1: unionPoint.x,
      y1: unionPoint.y,
      x2:
        parent1.x +
        20 +
        config.nodeSpacing / 2 +
        (unionNodePosition == "right") * config.nodeSpacing,
      y2: parent1.y + offset + 20 + config.nodeRowSpacing / 2,
      className: "union-" + colorVariation,
    },
    {
      x1:
        parent1.x +
        20 +
        config.nodeSpacing / 2 +
        (unionNodePosition == "right") * config.nodeSpacing,
      y1: parent1.y + offset + 20 + config.nodeRowSpacing / 2,
      x2: child.x + 20,
      y2: parent1.y + offset + 20 + config.nodeRowSpacing / 2,
      className: "union-" + colorVariation,
    },
    {
      x1: child.x + 20,
      y1: parent1.y + offset + 20 + config.nodeRowSpacing / 2,
      x2: child.x + 20,
      y2: child.y + 20,
      className: "union-" + colorVariation,
    },
  ];

  const sortParents = () => {
    if (parent1.x > parent2.x) {
      const tmp = parent2;
      parent2 = parent1;
      parent1 = tmp;
    }
  };

  sortParents();
  const unionNodePosition = Math.abs(parent1.x - parent2.x) > config.nodeSpacing ? "right" : "left";
  const unionPoint = {
    x:
      parent1.x + 20 + config.nodeSpacing / 2 + (unionNodePosition == "right") * config.nodeSpacing,
    y: parent1.y + offset + 20,
  };

  return (
    <>
      <circle cx={unionPoint.x} cy={unionPoint.y} r={5} className={"union-" + colorVariation} />
      {getLineParts().map((line, index) => {
        return (
          <>
            <line
              key={"union-line-" + line.x1 + "-" + line.x2 + "-" + line.y1 + "-" + line.y2}
              {...line}
            />
          </>
        );
      })}
    </>
  );
};

export default FamilyTreeChildLine;
