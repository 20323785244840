import React, { useState, useRef, useEffect } from "react";

import { useApi } from "../../contexts/ApiContext";
import { useUi } from "../../contexts/UiContext";
import DnaAnalysisResults from "./DnaAnalysisResults";
import "./DnaAnalysisPanel.css";
import arrowIcon from "../../assets/expand-arrow.svg";
import analyzingIcon from "../../assets/analyzing.svg";

/**
 * Panel component for displaying DNA analysis results.
 */
const DnaAnalysisPanel = () => {
  const api = useApi();
  const ui = useUi();
  const panelRef = useRef();
  const panelHeaderRef = useRef();
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [bottom, setBottom] = useState();

  const handleWindowResize = () => {
    setBottom(-panelRef.current.clientHeight + panelHeaderRef.current.clientHeight);
  };

  useEffect(() => {
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [panelRef.current]);

  return (
    <div
      className={`dna-analysis-panel ${ui.dnaAnalysisPanelExpanded ? " expanded" : ""}`}
      style={{
        bottom: ui.dnaAnalysisPanelExpanded ? 0 : bottom,
      }}
      ref={panelRef}
    >
      <div className="dna-analysis-panel-header" ref={panelHeaderRef}>
        <button
          className="expand-panel"
          title={ui.dnaAnalysisPanelExpanded ? "Collapse" : "Expand"}
          onClick={() => {
            if (ui.tutorialStep == 5) ui.setTutorialStep(6);
            ui.dnaAnalysisPanelExpanded
              ? ui.collapseDnaAnalysisPanel()
              : ui.expandDnaAnalysisPanel();
          }}
        >
          <img src={arrowIcon} />
        </button>
        <h3
          onClick={() => {
            if (ui.tutorialStep == 5) ui.setTutorialStep(6);
            ui.dnaAnalysisPanelExpanded
              ? ui.collapseDnaAnalysisPanel()
              : ui.expandDnaAnalysisPanel();
          }}
        >
          DNA Analysis
        </h3>
        <button
          className={`button ${isLoading ? "disabled" : ""}`}
          id="dna-analysis-panel-analyze-button"
          onClick={() => {
            if (!isLoading) {
              setIsLoading(true);
              const tutorialStep = ui.tutorialStep;
              api.analyze((analysis) => {
                ui.expandDnaAnalysisPanel();
                if (tutorialStep == 1) ui.setTutorialStep(2);
                setIsLoading(false);
                setResults(analysis.results);
              });
            }
          }}
        >
          {isLoading ? <img src={analyzingIcon} /> : "Analyze"}
        </button>
      </div>
      <DnaAnalysisResults results={results} />
    </div>
  );
};

export default DnaAnalysisPanel;
