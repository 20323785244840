import React, { useEffect } from "react";

import AddPersonModal from "../AddPersonModal/AddPersonModal";
import { useApi } from "../../contexts/ApiContext";
import { useUi } from "../../contexts/UiContext";
import "./PersonField.css";
import xIcon from "../../assets/x.svg";

/**
 * Field component for selecting a certain person.
 *
 * Child components passed in between the opening/closing tags will be rendered within the component.
 *
 * @param {{title: string, personId: string, onChange: Function, onClick:Function, onDelete: Function, children: JSX.Element}} props component properties object
 * @param props.title type of person that the field will be used to select e.g. Parent
 * @param props.personId id of the selected person
 * @param props.onChange callback for when selected person changes, with signature (personId: number|null)
 * @param props.onClick callback for when person clicked, with signature (personId: number)
 * @param props.onDelete callback for when person removed, with signature (personId: number)
 */
const PersonField = ({ title, personId, onChange, onClick, onDelete, children }) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const api = useApi();
  const ui = useUi();

  if (!personId) {
    return (
      <>
        <button
          className="add-person-button"
          onClick={() => {
            setModalOpen(true);
          }}
        >
          Add {title}
        </button>
        {modalOpen ? (
          <AddPersonModal
            title={title}
            onSubmit={(personId) => {
              setModalOpen(false);
              if (onChange) onChange(personId);
            }}
            onCancel={() => setModalOpen(false)}
          />
        ) : null}
      </>
    );
  }
  return (
    <div className={"person-field" + (children ? " has-children" : "")}>
      <button
        className="person-field-button"
        onClick={() => {
          if (onClick && personId[0] != "s") onClick(personId);
        }}
      >
        <div className="person-field-title">{title}</div>
        <div className="person-field-name">{api.getName(personId)}</div>
      </button>
      {personId[0] != "s" ? (
        <button
          className="person-field-delete"
          title={"Remove " + title}
          onClick={() => {
            ui.showLoadingModal("Updating person...");
            if (onChange) onChange(null);
            if (onDelete) onDelete(personId);
          }}
        >
          <img src={xIcon} />
        </button>
      ) : null}
      {children ? <div className="person-field-children">{children}</div> : null}
    </div>
  );
};

export default PersonField;
