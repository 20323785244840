const serverUrl = process.env.REACT_APP_SERVER_URL;

/**
 * Fetches an analysis from the MysteryFam API.
 *
 * @param {string} treeId id of tree to perform analysis on
 * @param {string|null} token authentication token, leave blank for demo mode
 * @param {Function} callback called on success, with signature (analysis: {results: array})
 */
const fetchAnalysis = async (treeId, token, callback) => {
  try {
    const request = {
      credentials: "include",
    };
    if (token) {
      request.headers = { Authorization: `Bearer ${token}` };
    }
    const response = await fetch(`${serverUrl}/analysis/?tree=${treeId}`, request);
    callback(await response.json());
  } catch (error) {
    console.log("Authentication error: " + error.message);
  }
};

/**
 * Creates a tree via the MysteryFam API.
 *
 * @param {string} name name of tree to create
 * @param {string|null} token authentication token, leave blank for demo mode
 * @param {Function} callback called on success, with signature (id: id of created tree)
 */
const createTree = async (name, token, callback) => {
  try {
    const request = {
      credentials: "include",
      method: "POST",
      body: JSON.stringify({ name: name }),
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
    }
    const response = await fetch(`${serverUrl}/tree`, request);

    const data = await response.json();
    if (callback) callback(data.id);
  } catch (error) {
    console.log("Authentication error: " + error.message);
  }
};

/**
 * Fetches all the user's tree from the MysteryFam API.
 *
 * @param {string|null} token authentication token, leave blank for demo mode
 * @param {Function} callback called on success with signature (trees: [{id, name}])
 */
const fetchTrees = async (token, callback) => {
  try {
    const request = {
      credentials: "include",
    };
    if (token) {
      request.headers = { Authorization: `Bearer ${token}` };
    }
    const response = await fetch(`${serverUrl}/tree`, request);
    const data = await response.json();
    if (callback) callback(data);
  } catch (error) {
    console.log("Authentication error: " + error.message);
  }
};

/**
 * Fetches a tree from the MysteryFam API.
 *
 * @param {string} id id of the tree to fetch
 * @param {string|null} token authentication token, leave blank for demo mode
 * @param {Function} callback called on success with signature ({id, name, nodes})
 */
const fetchTree = async (id, token, callback) => {
  try {
    const request = {
      credentials: "include",
    };
    if (token) {
      request.headers = { Authorization: `Bearer ${token}` };
    }
    const response = await fetch(`${serverUrl}/tree/${id}`, request);
    const tree = await response.json();
    tree.nodes = tree.nodes.map((node) => {
      return nodeFromResource(node);
    });
    if (callback) callback(tree);
  } catch (error) {
    console.log("Authentication error: " + error.message);
  }
};

/**
 * Creates a node on a given tree via the MysteryFam API.
 *
 * @param {string} name name of the person to add
 * @param {string} treeId id of tree to add person to
 * @param {string|null} token authentication token, leave blank for demo mode
 * @param {Function} callback called on success with signature (id: id of added person)
 */
const createNode = async (name, treeId, token, callback) => {
  try {
    const request = {
      credentials: "include",
      method: "POST",
      body: JSON.stringify({ name: name }),
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
    }
    const response = await fetch(`${serverUrl}/tree/${treeId}/node`, request);

    const data = await response.json();
    if (callback) callback(data.id);
  } catch (error) {
    console.log("Authentication error: " + error.message);
  }
};

/**
 * Fetches all nodes matching given query via the MysteryFam API.
 *
 * @param {string} query query to match nodes against
 * @param {string} treeId id of tree to search
 * @param {string|null} token authentication token, leave blank for demo mode
 * @param {Function} callback called on success with signature ([{id, name}])
 */
const fetchNodesByQuery = async (query, treeId, token, callback) => {
  try {
    const request = {
      credentials: "include",
    };
    if (token) {
      request.headers = { Authorization: `Bearer ${token}` };
    }
    const response = await fetch(`${serverUrl}/tree/${treeId}/node/?query=${query}`, request);
    const nodes = (await response.json()).map((node) => {
      return nodeFromResource(node);
    });
    if (callback) callback(nodes);
  } catch (error) {
    console.log("Authentication error: " + error.message);
  }
};

/**
 * Applies the given props to an existing node via the MysteryFam API.
 *
 * @param {string} id id of node to update
 * @param {Array} props associative array of props to apply to node
 * @param {string} treeId id of tree to find node in
 * @param {string|null} token authentication token, leave blank for demo mode
 * @param {Function} callback called on success
 */
const updateNode = async (id, props, treeId, token, callback) => {
  try {
    const request = {
      credentials: "include",
      method: "PATCH",
      body: JSON.stringify(resourceFromNode(props)),
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
    }
    const response = await fetch(`${serverUrl}/tree/${treeId}/node/${id}`, request);

    if (callback) callback();
  } catch (error) {
    console.log("Authentication error: " + error.message);
  }
};

/**
 * Deletes a given node from a tree via the MysteryFam API.
 *
 * @param {string} id id of node to delete
 * @param {string} treeId id of tree to delete node from
 * @param {string|null} token authentication token, leave blank for demo mode
 * @param {Function} callback called on success
 */
const deleteNode = async (id, treeId, token, callback) => {
  try {
    const request = {
      credentials: "include",
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
    }
    const response = await fetch(`${serverUrl}/tree/${treeId}/node/${id}`, request);

    if (callback) callback();
  } catch (error) {
    console.log("Authentication error: " + error.message);
  }
};

const nodeFromResource = (resource) => {
  return {
    id: resource.id,
    uri: resource.self,
    name: resource.name,
    cluster: resource.cluster,
    dateOfBirth: resource.dateOfBirth,
    sharedDna: resource.sharedDna,
    parent1: resource.parent1 ? resource.parent1.split("/").pop() : null,
    parent2: resource.parent2 ? resource.parent2.split("/").pop() : null,
    unions: resource.unions.map((union) => {
      return {
        spouse: union.spouse ? union.spouse.split("/").pop() : null,
        children: union.children.map((child) => {
          return child.split("/").pop();
        }),
      };
    }),
  };
};

const resourceFromNode = (node) => {
  const resource = {};
  Object.keys(node).forEach((key) => {
    if (key == "parent1" && node.parent1) {
      resource.parent1 = `${serverUrl}/node/${node.parent1}`;
    } else if (key == "parent2" && node.parent2) {
      resource.parent2 = `${serverUrl}/node/${node.parent2}`;
    } else if (key == "unions") {
      resource.unions = node.unions.map((union) => {
        return {
          spouse: union.spouse ? `${serverUrl}/node/${union.spouse}` : null,
          children: union.children.map((childId) => {
            return `${serverUrl}/node/${childId}`;
          }),
        };
      });
    } else if (key != "uri") {
      resource[key] = node[key];
    }
  });
  return resource;
};

/**
 * Creates a demo user/tree via the MysteryFam API. Demo token stored as cookie.
 *
 * @param {Function} callback called on success
 */
const createDemoUser = async (callback) => {
  try {
    const response = await fetch(`${serverUrl}/demo`, {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      method: "POST",
    });
    if (response.status == 200 || response.status == 201) {
      if (callback) callback();
    } else {
      //what to do on failure
    }
  } catch (error) {
    console.log("Authentication error: " + error.message);
  }
};

export {
  fetchAnalysis,
  createTree,
  fetchTrees,
  fetchTree,
  createNode,
  fetchNodesByQuery,
  updateNode,
  deleteNode,
  createDemoUser,
};
