import React, { useState, useEffect, useRef } from "react";

import { useApi } from "../../contexts/ApiContext";
import "./PersonSearchForm.css";

/**
 * Form component for finding a person by name. Displays a dropdown list of matches
 * to select from.
 *
 * An optional onAddNew callback can be passed, which will display an option to allow
 * a new person to be created with the searched name instead.
 *
 * @param {{onSelect: Function, onAddNew: Function}} props component properties object
 * @param props.onSelect callback for when person selected, with signature (id: string)
 * @param props.onAddNew callback for when new person is added, with signature (name: string)
 */
const PersonSearchForm = ({ onSelect, onAddNew }) => {
  const nameInput = useRef(null);
  const api = useApi();
  const queryTimer = useRef(null);
  const [results, setResults] = useState([]);
  const [queryString, setQueryString] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const queryChanged = (query) => {
    setQueryString(query);
    setIsLoading(true);
    clearTimeout(queryTimer.current);
    queryTimer.current = setTimeout(() => {
      api.searchPeople(query, (nodes) => {
        setResults(nodes);
        setIsLoading(false);
      });
    }, 300);
  };

  useEffect(() => {
    nameInput.current.focus();
  }, []);

  return (
    <form
      className="person-search-form"
      data-testid="person-search-form"
      onKeyPress={(e) => {
        if (e.key == "Enter" && !isLoading) {
          e.preventDefault();
          if (onAddNew) {
            onAddNew(queryString);
          } else if (results.length > 0) {
            onSelect(results[0].id);
          }
        }
      }}
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <input
        type="text"
        autoComplete="off"
        placeholder="Enter Name"
        name="name"
        value={queryString}
        onChange={(e) => queryChanged(e.target.value)}
        ref={nameInput}
      />
      {queryString != "" ? (
        <ul className="person-search-results" data-testid="person-search-results">
          {onAddNew ? (
            <>
              <li>
                <button
                  className="add-new"
                  data-testid="add-new"
                  onClick={() => {
                    onAddNew(queryString);
                  }}
                >
                  + Add New (<em>Shortcut:</em> Enter)
                </button>
              </li>
            </>
          ) : null}
          {isLoading ? (
            <li>
              <button style={{ pointerEvents: "none" }}>Searching...</button>
            </li>
          ) : (
            results.map((result) => {
              return (
                <li>
                  <button
                    type="button"
                    onClick={() => {
                      onSelect(result.id);
                    }}
                  >
                    {result.name + " (ID: " + result.id + ")"}
                  </button>
                </li>
              );
            })
          )}
        </ul>
      ) : null}
    </form>
  );
};

export default PersonSearchForm;
