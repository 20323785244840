import React, { useState } from "react";
import { useApi } from "../../contexts/ApiContext";
import { useUi } from "../../contexts/UiContext";
import PersonDetailsForm from "../PersonDetailsForm/PersonDetailsForm";
import PersonSearchForm from "../PersonSearchForm/PersonSearchForm";

/**
 * Modal window component for adding a person to the tree.
 *
 * @param {{title: string, onSubmit: Function, onCancel: Function}} props component properties object
 * @param props.title type of person to add e.g. Parent
 * @param props.onSubmit callback for form submit, with signature (id: number representing id of added node)
 * @param props.onCancel callback for form cancel
 */
const AddPersonModal = ({ title, onSubmit, onCancel }) => {
  const api = useApi();
  const ui = useUi();
  const [personId, setPersonId] = useState();
  const [creating, setCreating] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    name: "",
    dateOfBirth: "",
    sharedDna: "",
  });

  const save = (callback) => {
    if (!isLoading) {
      setIsLoading(true);
      ui.showLoadingModal("Updating person...");
      api.setPersonDetails(personId, data.name, data.dateOfBirth, data.sharedDna, () => {
        setIsLoading(false);
        callback();
      });
    }
  };

  const cancel = () => {
    if (creating) {
      ui.showLoadingModal("Deleting person...");
      api.deletePerson(personId, function () {
        ui.hideLoadingModal();
      });
    }
    if (onCancel) onCancel();
  };

  return (
    <>
      <div className="dim" onClick={cancel}></div>
      <div className="add-person modal" style={{ width: 400 }}>
        <h2>Add {title}</h2>
        {creating ? (
          <>
            <PersonDetailsForm
              personId={personId}
              onChange={setData}
              onSubmit={() => {
                save(() => {
                  onSubmit(personId);
                });
              }}
            />
            <button
              className="button"
              onClick={() => {
                save(() => {
                  onSubmit(personId);
                });
              }}
            >
              Save
            </button>
          </>
        ) : (
          <PersonSearchForm
            onAddNew={(name) => {
              if (!isLoading) {
                setIsLoading(true);
                ui.showLoadingModal("Adding person...");
                api.addPerson(name, (id) => {
                  setPersonId(id);
                  setData({
                    name: name,
                    dateOfBirth: null,
                    sharedDna: null,
                  });
                  setCreating(true);
                  setIsLoading(false);
                  ui.hideLoadingModal();
                });
              }
            }}
            onSelect={(id) => {
              ui.showLoadingModal("Updating person...");
              onSubmit(id);
            }}
          />
        )}
        <button className="button" onClick={cancel}>
          Cancel
        </button>
      </div>
    </>
  );
};

export default AddPersonModal;
