import { useAuth0 } from "@auth0/auth0-react";
import React, { useState, useContext, useEffect } from "react";

import * as api from "./api";

const ApiContext = React.createContext();

/**
 * Context provider that makes it easy to interact with a tree via the MysteryFam API.
 *
 * Provides all necessary functions for manipulating and gaining information about
 * the tree and the people in it, for executing DNA analysis, as well as for
 * authenticating with Auth0.
 */
const ApiProvider = ({ children }) => {
  const [clusterCache, setClusterCache] = useState([]);
  const [cacheVersion, setCacheVersion] = useState(null);
  const [isDemoMode, setDemoMode] = useState(false);
  const [treeId, setTreeId] = useState(null);
  const [token, setToken] = useState(null);
  const [isInitialized, setInitialized] = useState(false);

  const auth0 = useAuth0();

  useEffect(() => {
    if (!auth0.isAuthenticated || isDemoMode) {
      setToken(null);
    } else {
      (async () => {
        setToken(await auth0.getAccessTokenSilently());
      })();
    }
  }, [auth0.isAuthenticated, isDemoMode]);

  useEffect(() => {
    if (isDemoMode || token != null) initialize();
  }, [token, isDemoMode]);

  const initialize = () => {
    api.fetchTrees(token, (trees) => {
      if (trees.length > 0) {
        selectTree(trees[0].id);
        setInitialized(true);
      } else {
        api.createTree("My Tree", token, (id) => {
          selectTree(id);
          setInitialized(true);
        });
      }
    });
  };

  const selectTree = (id, callback) => {
    api.fetchTree(id, token, (tree) => {
      setTreeId(id);
      setClusterCache(sortIntoClusters(tree.nodes));
      setCacheVersion(new Date().getTime());
      if (callback) callback();
    });
  };

  const sortIntoClusters = (nodes) => {
    const clusters = [];
    nodes.forEach((node) => {
      while (clusters.length <= node.cluster) {
        clusters.push([]);
      }
      clusters[node.cluster].push(node);
    });

    return clusters;
  };

  /**
   * Analyzes the selected tree and calls the provided callback with the results.
   * @param {function} callback callback called with signature (analysis: {results: array})
   */
  const analyze = async (callback) => {
    api.fetchAnalysis(treeId, token, callback);
  };

  /**
   * Adds a person with the given name to the tree.
   * @param {string} name
   * @param {Function} callback callback called with signature (id: number representing id of added person)
   */
  const addPerson = async (name, callback) => {
    api.createNode(name, treeId, token, (id) => {
      selectTree(treeId, () => {
        if (callback) callback(id);
      });
    });
  };

  /**
   * Deletes person with given id from the tree.
   * @param {string} personId
   * @param {Function} callback called on success
   */
  const deletePerson = async (personId, callback) => {
    api.deleteNode(personId, treeId, token, () => {
      selectTree(treeId, callback);
    });
  };

  /**
   * Calls callback with an array of data objects of people whose names match
   * query string.
   *
   * @param {string} query
   * @param {Function} callback called on success with signature ([node: {id: number, name: string}])
   */
  const searchPeople = async (query, callback) => {
    api.fetchNodesByQuery(query, treeId, token, callback);
  };

  /**
   * @param {string} personId
   * @param {string|null} name
   * @param {string|null} dateOfBirth
   * @param {number|null} sharedDna amount of shared DNA in centimorgans
   * @param {Function} callback called on success
   */
  const setPersonDetails = (personId, name, dateOfBirth, sharedDna, callback) => {
    updatePerson(
      personId,
      { name: name, dateOfBirth: dateOfBirth, sharedDna: sharedDna },
      callback
    );
  };

  /**
   * @param {string} personId
   * @returns {string} name of person
   */
  const getName = (personId) => {
    const person = getPerson(personId);
    if (!person) return "Unknown";
    return person.name;
  };

  /**
   * @param {string} personId
   * @returns {string} string representing date of birth
   */
  const getDateOfBirth = (personId) => {
    const person = getPerson(personId);
    return person ? person.dateOfBirth : null;
  };

  /**
   * @param {string} personId
   * @returns {number} shared DNA in centimorgans
   */
  const getSharedDna = (personId) => {
    const person = getPerson(personId);
    return person ? person.sharedDna : null;
  };

  /**
   * @param {string} childId
   * @param {string} parent1Id
   * @param {Function} callback called on success
   */
  const setParent1 = (childId, parent1Id, callback) => {
    updatePerson(childId, { parent1: parent1Id }, callback);
  };

  /**
   * @param {string} childId id of person to get first parent of
   * @returns {string} id of the parent
   */
  const getParent1 = (childId) => {
    const person = getPerson(childId);
    return person ? person.parent1 : null;
  };

  /**
   * @param {string} childId
   * @param {string} parent2Id
   * @param {Function} callback called on success
   */
  const setParent2 = (childId, parent2Id, callback) => {
    updatePerson(childId, { parent2: parent2Id }, callback);
  };

  /**
   * @param {string} childId id of person to get second parent of
   * @returns {string} id of the parent
   */
  const getParent2 = (childId) => {
    const person = getPerson(childId);
    return person ? person.parent2 : null;
  };

  /**
   *
   * @param {string} childId id of person to get parents of
   * @returns {Array} array containing ids of both parents
   */
  const getParents = (childId) => {
    const person = getPerson(childId);
    if (!person) return [null, null];
    return [person.parent1, person.parent2];
  };

  /**
   * Creates a spousal union between two people.
   *
   * @param {string} personId id of person to add spouse to
   * @param {string} spouseId id of spouse to add
   * @param {Function} callback called on success
   */
  const addSpouse = (personId, spouseId, callback) => {
    const unions = getPerson(personId).unions;
    unions.push({ spouse: spouseId, children: [] });
    updatePerson(personId, { unions: unions }, callback);
  };

  /**
   * Dissociates two spouses.
   *
   * @param {string} personId id of person to remove spouse from
   * @param {string} spouseId id of spouse to remove
   * @param {Function} callback called on success
   */
  const removeSpouse = (personId, spouseId, callback) => {
    const unions = getPerson(personId).unions.filter((union) => union.spouse != spouseId);
    updatePerson(personId, { unions: unions }, callback);
  };

  /**
   * Gets an array of the ids of the spouses of a given person.
   *
   * Can optionally return a dummy id to represent an unknown spouse indicated by children of the
   * person whose other parent isn't known.
   *
   * @param {string} spousesOfId id of the person whose spouses should be retrieved
   * @param {*} includeUnknown whether to include dummy id representing unknown spouse, if indicated by children of the
   *                           person whose other parent isn't known.
   * @returns {Array} array of spouse ids
   */
  const getSpouses = (spousesOfId, includeUnknown) => {
    if (typeof includeUnknown == "undefined") includeUnknown = true;
    const person = getPerson(spousesOfId);
    let spouses = person
      ? person.unions.map((union) => (union.spouse != null ? union.spouse : "s" + spousesOfId))
      : [];

    if (includeUnknown == false) {
      spouses = spouses.filter((id) => id != "s" + spousesOfId);
    }
    return spouses;
  };

  /**
   * Creates a parent/child relationship between specified parent node(s) and a child node.
   * If a second parent is specified, creates a spousal union between the two parents.
   *
   * @param {string} personId id of first parent to add child to
   * @param {string|null} spouseId if specified, will add child to a second parent and
   *                                create a spousal union between the two parents
   * @param {string} childId id of child to add
   * @param {Function} callback called on success
   */
  const addChild = (personId, spouseId, childId, callback) => {
    if (spouseId == "s" + personId) spouseId = null;
    const unions = getPerson(personId).unions;
    unions.forEach((union) => {
      if (union.spouse == spouseId) {
        union.children.push(childId);
      }
    });
    updatePerson(personId, { unions: unions }, callback);
  };

  /**
   * Removes a parent/child relationship between specified parent node(s) and a child node.
   *
   * Does NOT remove the spousal union between the two parents.
   *
   * @param {string} personId id of parent to remove child from
   * @param {string|null} spouseId if specified, will remove child from second parent
   * @param {string} childId id of child to add
   * @param {Function} callback called on success
   */
  const removeChild = (personId, spouseId, childId, callback) => {
    if (spouseId == "s" + personId) spouseId = null;
    const unions = getPerson(personId).unions;
    unions.forEach((union) => {
      if (union.spouse == spouseId) {
        union.children = union.children.filter((id) => id != childId);
      }
    });
    updatePerson(personId, { unions: unions }, callback);
  };

  /**
   * Gets an array of the ids of the child of given parent(s).
   *
   * If two parents provide, will return only the children of that union.
   *
   * If only one parent provided, will return all children of that parent.
   *
   * If dummy id provided as second parent (id of first parent wih an "s" in front
   * of it e.g. s256), will retrieve only the children of first parent whose other parent
   * is unknown.
   *
   * @param {string|null} spouse1Id id of first parent to get children of
   * @param {string|null} spouse2Id id of second parent to get children of
   * @return {Array} array of child ids
   */
  const getChildren = (spouse1Id, spouse2Id) => {
    //two actual personId's provided
    if (
      typeof spouse1Id != "undefined" &&
      typeof spouse2Id != "undefined" &&
      spouse1Id != null &&
      spouse2Id != null &&
      spouse1Id != "s" + spouse2Id &&
      spouse2Id != "s" + spouse1Id
    ) {
      return getPeople()
        .filter((person) => {
          return (
            (getParent1(person.id) == spouse1Id || getParent1(person.id) == spouse2Id) &&
            (getParent2(person.id) == spouse1Id || getParent2(person.id) == spouse2Id)
          );
        })
        .map((person) => person.id);
    }

    //person1 provided with dummy "unknown spouse" for person2
    if (spouse2Id == "s" + spouse1Id) {
      return getPeople()
        .filter((person) => {
          return (
            (getParent1(person.id) == spouse1Id && getParent2(person.id) == null) ||
            (getParent1(person.id) == null && getParent2(person.id) == spouse1Id)
          );
        })
        .map((person) => person.id);
    }

    //person2 provided with dummy "unknown spouse" for person1
    if (spouse1Id == "s" + spouse2Id) {
      return getPeople()
        .filter((person) => {
          return (
            (getParent1(person.id) == spouse2Id && getParent2(person.id) == null) ||
            (getParent1(person.id) == null && getParent2(person.id) == spouse2Id)
          );
        })
        .map((person) => person.id);
    }

    //only person1 provided
    if (spouse1Id != "s" + spouse2Id && typeof spouse1Id != "undefined") {
      return getPeople()
        .filter((person) => {
          return getParent1(person.id) == spouse1Id || getParent2(person.id) == spouse1Id;
        })
        .map((person) => person.id);
    }

    //only person2 provided
    if (spouse2Id != "s" + spouse1Id && typeof spouse2Id != "undefined") {
      return getPeople()
        .filter((person) => {
          return getParent1(person.id) == spouse2Id || getParent2(person.id) == spouse2Id;
        })
        .map((person) => person.id);
    }
  };

  /**
   * Gets an array containing the ids of the siblings of a given person,
   * optionally including half siblings.
   *
   * @param {string} personId
   * @param {boolean} includeHalf whether to include half siblings
   * @returns {Array} array of sibling ids
   */
  const getSiblings = (personId, includeHalf) => {
    const parent1 = getParent1(personId);
    const parent2 = getParent2(personId);
    if (!parent1 || !parent2) return [];
    return includeHalf
      ? [...new Set([...getChildren(parent1), ...getChildren(parent2)])].filter(
          (id) => id != personId
        )
      : getChildren(parent1, parent2).filter((id) => id != personId);
  };

  const updatePerson = async (id, props, callback) => {
    api.updateNode(id, props, treeId, token, () => {
      selectTree(treeId, callback);
    });
  };

  /**
   * Gets the id of a person adjacent to the selected person in the tree.
   * Useful for finding another person to select after deleting the selected node.
   *
   * @param {string} personId
   * @returns {string} id of adjacted person
   */
  const getAdjacentPerson = (personId) => {
    const parents = getParents(personId);

    if (parents[0]) return parents[0];
    if (parents[1]) return parents[1];

    const spouses = getSpouses(personId, false);
    if (spouses[0]) return spouses[0];

    const children = getChildren(personId);

    if (children[0]) return children[0];

    const clusterPrimaries = getClusterRoots();
    let newClusterIndex = getContainingClusterIndex(personId);
    newClusterIndex++;
    if (newClusterIndex >= clusterPrimaries.length) {
      newClusterIndex -= 2;
    }
    if (newClusterIndex >= 0) {
      return clusterPrimaries[newClusterIndex];
    }

    return false;
  };

  const getPerson = (id) => {
    return getPeople().filter((person) => person.id == id)[0];
  };

  const getPeople = () => {
    return [].concat(...clusterCache);
  };

  /**
   * Gets an array containing the ids of the "root" people in each node cluster
   * of the family tree. Useful to serve as the default selected people in each cluster.
   
  * @returns {Array} array containing the ids of the "root" people in each node cluster
   */
  const getClusterRoots = () => {
    if (!clusterCache) return null;
    return clusterCache.map((cluster) => {
      return cluster[0].id;
    });
  };

  /**
   * Gets the index of the node cluster containing the given person.
   *
   * @param {string} personId
   * @returns {number} index of node cluster containing given person
   */
  const getContainingClusterIndex = (personId) => {
    for (var c = 0; c < clusterCache.length; c++) {
      for (var p = 0; p < clusterCache[c].length; p++) {
        if (clusterCache[c][p].id == personId) return c;
      }
    }
  };

  /**
   * Gets the number of people contained in a given node cluster.
   *
   * @param {number} clusterIndex
   * @returns {number} number of people in cluster
   */
  const getClusterSize = (clusterIndex) => {
    return clusterCache[clusterIndex] ? clusterCache[clusterIndex].length : null;
  };

  /**
   * Gets the version of the cached tree data. Useful for updating the visual representation
   * of the tree whenever the tree changes on the server.
   *
   * @returns {number} version of the cache
   */
  const getCacheVersion = () => {
    return cacheVersion;
  };

  /**
   * Initializes demo mode, creating a new demo tree when necessary. After invoking this method,
   * all operations during this session will be performed on the demo tree.
   *
   * @param {Function} callback called on success
   */
  const enterDemoMode = async (callback) => {
    api.createDemoUser(() => {
      setDemoMode(true);
      if (callback) callback();
    });
  };

  return (
    <ApiContext.Provider
      value={{
        isInitialized,
        analyze,
        addPerson,
        deletePerson,
        searchPeople,
        setPersonDetails,
        getName,
        getDateOfBirth,
        getSharedDna,
        setParent1,
        getParent1,
        setParent2,
        getParent2,
        getParents,
        addSpouse,
        removeSpouse,
        getSpouses,
        addChild,
        removeChild,
        getChildren,
        getSiblings,
        getAdjacentPerson,
        getClusterRoots,
        getContainingClusterIndex,
        getClusterSize,
        getCacheVersion,
        enterDemoMode,
        isDemoMode,
        login: auth0.loginWithRedirect,
        logout: auth0.logout,
        isAuthenticated: auth0.isAuthenticated,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};

/**
 * ```js
 * const {
 *    isInitialized,
 *    analyze,
 *    addPerson,
 *    deletePerson,
 *    searchPeople,
 *    setPersonDetails,
 *    getName,
 *    getDateOfBirth,
 *    getSharedDna,
 *    setParent1,
 *    getParent1,
 *    setParent2,
 *    getParent2,
 *    getParents,
 *    addSpouse,
 *    removeSpouse,
 *    getSpouses,
 *    addChild,
 *    removeChild,
 *    getChildren,
 *    getSiblings,
 *    getAdjacentPerson,
 *    getClusterRoots,
 *    getContainingClusterIndex,
 *    getClusterSize,
 *    getCacheVersion,
 *    enterDemoMode,
 *    isDemoMode,
 *    login,
 *    logout,
 *    isAuthenticated
 * } = useApi();
 * ```
 *
 * Use the `useApi` hook in your components to interact with a tree via the MysteryFam API.
 *
 * Provides all necessary functions for manipulating and gaining information about the tree and
 * the people in it, for executing DNA analysis, as well as for authenticating with Auth0.
 */
export const useApi = () => {
  return useContext(ApiContext);
};

export { ApiContext, ApiProvider };
