import React, { useState, setState, useEffect } from "react";

import icon from "../../assets/icon.svg";
import "./LoadingModal.css";

/**
 * Modal component for displaying a loading message.
 *
 * @param {{message: string}} props component properties object
 * @param props.message loading message to display in the modal
 */
const LoadingModal = ({ message }) => {
  return (
    <>
      <div className="dim loading"></div>
      <div className="loading modal">
        <h2>{message}</h2>
        <img src={icon} />
      </div>
    </>
  );
};

export default LoadingModal;
