import React, { useEffect, useState } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import { useApi } from "../../contexts/ApiContext";
import { useUi } from "../../contexts/UiContext";
import FindPersonModal from "../FindPersonModal/FindPersonModal";
import FamilyTree from "../FamilyTree/FamilyTree";
import "./FamilyTreePanel.css";
import plusIcon from "../../assets/plus.svg";
import searchIcon from "../../assets/search.svg";

/**
 * Panel component that renders a FamilyTree, allows zooming and panning,
 * and provides a GUI for adding and searching.
 *
 * @param {{personId: string}} props component properties object
 * @param props.personId id of the person to render the tree for
 */
const FamilyTreePanel = ({ personId }) => {
  const api = useApi();
  const ui = useUi();
  const [findPersonModalOpen, setFindPersonModalOpen] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  return (
    <div
      className="family-tree-window"
      onMouseDown={(e) => {
        setMousePosition({ x: e.screenX, y: e.screenY });
      }}
      onClick={(e) => {
        let parent = e.target;
        while (parent != null) {
          if (
            parent.classList &&
            (parent.classList.contains("family-tree-node") || parent.nodeName == "BUTTON")
          )
            return;

          parent = parent.parentNode;
        }
        if (
          Math.abs(e.screenX - mousePosition.x) < 2 &&
          Math.abs(e.screenY - mousePosition.y) < 2
        ) {
          ui.closeEditPersonPanel();
        }
      }}
    >
      <TransformWrapper
        limitToBounds={false}
        centerOnInit={true}
        initialScale={window.innerHeight / 1080}
        minScale={0.5}
      >
        <TransformComponent>
          <div
            className="family-tree-container"
            onMouseDown={() =>
              document.querySelector(".family-tree-container").classList.add("dragging")
            }
            onMouseUp={() =>
              document.querySelector(".family-tree-container").classList.remove("dragging")
            }
          >
            <FamilyTree
              personId={personId}
              onSelect={(node, edit) => {
                ui.setPersonId(node.id);
                if (edit) ui.openEditPersonPanel();
              }}
            />
          </div>
        </TransformComponent>
      </TransformWrapper>
      <div className="tree-gui">
        <button
          className="search-button"
          title="Find Person"
          onClick={() => setFindPersonModalOpen(true)}
        >
          <img src={searchIcon} />
        </button>
        <button
          className="add-button"
          title="Add Person"
          onClick={() => {
            ui.showLoadingModal("Adding person...");
            api.addPerson("New Person", (id) => {
              ui.setPersonId(id);
              ui.openEditPersonPanel();
              ui.hideLoadingModal();
            });
          }}
        >
          <img src={plusIcon} />
        </button>
        {findPersonModalOpen ? (
          <FindPersonModal
            onSubmit={(personId) => {
              setFindPersonModalOpen(false);
              ui.setPersonId(personId);
            }}
            onCancel={() => setFindPersonModalOpen(false)}
          />
        ) : null}
      </div>
    </div>
  );
};

export default FamilyTreePanel;
