import React, { useState, useContext, useEffect } from "react";

const UiContext = React.createContext();

/**
 * Maintains information about the UI state.
 */
const UiProvider = ({ children }) => {
  const [personId, setPersonId] = useState(1);
  const [loadingMessage, setLoadingMessage] = useState(null);
  const [editPersonPanelOpen, setEditPersonPanelOpen] = useState(false);
  const [dnaAnalysisPanelExpanded, setDnaAnalysisPanelExpanded] = useState(false);
  const [tutorialStep, setTutorialStep] = useState(1);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const openEditPersonPanel = () => {
    setEditPersonPanelOpen(true);
  };

  const closeEditPersonPanel = () => {
    setEditPersonPanelOpen(false);
  };

  const expandDnaAnalysisPanel = () => {
    setDnaAnalysisPanelExpanded(true);
  };

  const collapseDnaAnalysisPanel = () => {
    setDnaAnalysisPanelExpanded(false);
  };

  const showLoadingModal = (message) => {
    if (typeof message == undefined) {
      message = "Loading data. Please wait...";
    }

    setLoadingMessage(message);
  };
  const hideLoadingModal = () => {
    setLoadingMessage(null);
  };

  const showMobileMenu = () => {
    setMobileMenuOpen(true);
  };

  const hideMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  return (
    <UiContext.Provider
      value={{
        setPersonId,
        personId,
        editPersonPanelOpen,
        dnaAnalysisPanelExpanded,
        expandDnaAnalysisPanel,
        collapseDnaAnalysisPanel,
        openEditPersonPanel,
        closeEditPersonPanel,
        showLoadingModal,
        hideLoadingModal,
        loadingMessage,
        tutorialStep,
        setTutorialStep,
        mobileMenuOpen,
        showMobileMenu,
        hideMobileMenu,
      }}
    >
      {children}
    </UiContext.Provider>
  );
};

export const useUi = () => {
  return useContext(UiContext);
};

export { UiContext, UiProvider };
