import { useEffect, useState } from "react";
import { useApi } from "../contexts/ApiContext";

/**
 * Used by useNodeLayout hook. Hook that generates an array matrix for use in laying out the family tree
 * for a specified person. Contains the ids of the related people, grouped into
 * logical groupings (such as parents and their spouses, grandparents, etc) and
 * placed in the exact order that they should be displayed in the tree.
 *
 * Example of array format of the matrix is as follows:
 * ```js
 * [
 *  [[232, 345], [23, 435], [563, 345]],
 *  [[12, 445], [353, 415, 578], [263, 525], [342, 87]],
 *  [[21, 35], [25], [984, 321]],
 * ]
 * ```
 *
 * @param {*} personId
 * @returns {{matrix: Array, relatedMatrices: Array}} above-mentioned matrix and a relatedMatrices array,
 * which contains the matrices for each of the nodes in the original matrix,
 * indexed by the id of the node.
 */
const useNodeMatrix = (personId) => {
  const api = useApi();
  const [matrix, setMatrix] = useState([]);
  const [relatedMatrices, setRelatedMatrices] = useState([]);

  useEffect(() => {
    const newMatrix = getNodeMatrix(personId);
    if (JSON.stringify(newMatrix) == JSON.stringify(matrix)) return;
    setMatrix(newMatrix);
    const relatedMatrices = [];
    newMatrix
      .flat(2)
      .filter((id) => id != personId)
      .forEach((id) => (relatedMatrices[id] = getNodeMatrix(id)));
    setRelatedMatrices(relatedMatrices);
  }, [personId, api.getCacheVersion()]);

  const getNodeMatrix = (personId) => {
    const rootRow = [
      getOtherChildrenOfParentGroup(1, personId),
      getRootPersonAndSpousesGroup(personId),
      getOtherChildrenOfParentGroup(2, personId),
    ];

    return [
      [getGrandparentsGroup(1, personId), getGrandparentsGroup(2, personId)],
      [
        getSiblingsOfParentGroup(1, personId),
        getParentsAndTheirOtherSpousesGroup(personId),
        getSiblingsOfParentGroup(2, personId),
      ],
      rootRow[1].length > 1 ? rootRow : [rootRow.flat()],
      getChildrenAndTheirSpousesGroups(personId),
      getGrandchildrenGroups(personId),
    ]
      .map((row) => row.filter((group) => group.length > 0))
      .filter((row) => row.length > 0);
  };

  const getGrandparentsGroup = (groupIndex, personId) => {
    const parents = api.getParents(personId);
    parents.sort();

    const grandparents = api.getParents(parents[groupIndex - 1]);

    if (grandparents[0] && !grandparents[1]) grandparents[1] = "s" + grandparents[0];
    if (grandparents[1] && !grandparents[0]) grandparents[0] = "s" + grandparents[1];

    return grandparents.filter((id) => id != null);
  };

  const getSiblingsOfParentGroup = (groupIndex, personId) => {
    const parents = api.getParents(personId);
    parents.sort();
    return api.getSiblings(parents[groupIndex - 1]);
  };

  const getParentsAndTheirOtherSpousesGroup = (personId) => {
    const parents = api.getParents(personId);
    parents.sort();
    if (parents[0] && !parents[1]) parents[1] = "s" + parents[0];
    if (parents[1] && !parents[0]) parents[0] = "s" + parents[1];

    const parentGroup = [];
    if (parents[0]) {
      const parent1OtherSpouses = api.getSpouses(parents[0]).filter((id) => id != parents[1]);
      parentGroup.push(...parent1OtherSpouses);
      parentGroup.push(parents[0]);
    }
    if (parents[1]) {
      parentGroup.push(parents[1]);
      const parent2OtherSpouses = api.getSpouses(parents[1]).filter((id) => id != parents[0]);
      parentGroup.push(...parent2OtherSpouses);
    }

    return parentGroup;
  };

  const getOtherChildrenOfParentGroup = (groupIndex, personId) => {
    const parents = api.getParents(personId).sort();
    if (parents[0] && !parents[1]) parents[1] = "s" + parents[0];
    if (parents[1] && !parents[0]) parents[0] = "s" + parents[1];

    let siblings = [];
    if (parents[groupIndex - 1]) {
      const parentOtherSpouses = api
        .getSpouses(parents[groupIndex - 1])
        .filter((id) => id != parents[groupIndex - 1 == 0 ? 1 : 0]);

      siblings = parentOtherSpouses
        .map((spouseId) => {
          return api.getChildren(parents[groupIndex - 1], spouseId);
        })
        .flat();

      if (parents[0] && parents[1]) {
        api
          .getChildren(parents[0], parents[1])
          .filter((id) => id != personId)
          .forEach((siblingId, index) => {
            if (groupIndex == 2 && index % 2 == 0) siblings.unshift(siblingId);
            if (groupIndex == 1 && index % 2 != 0) siblings.push(siblingId);
          });
      }
    }
    return siblings;
  };

  const getRootPersonAndSpousesGroup = (personId) => {
    const spouses = api.getSpouses(personId);

    let group = [];
    if (spouses.length > 0) {
      group = [].concat(
        ...spouses.map((spouseId, index) => {
          return index == Math.floor(spouses.length / 2) ? [personId, spouseId] : [spouseId];
        })
      );
    } else {
      group = [personId];
    }
    return group;
  };

  const getChildrenAndTheirSpousesGroups = (personId) => {
    const childSpouses = [];
    const spouses = api.getSpouses(personId);
    const group = spouses.map((spouseId, index) => {
      const children = api.getChildren(spouseId, personId);
      return [].concat(
        ...children.map((childId) => {
          childSpouses[childId] = api.getSpouses(childId);
          return [].concat(
            ...(childSpouses[childId].length > 0
              ? childSpouses[childId].map((spouseId, index) => {
                  return index == Math.floor(childSpouses[childId].length / 2)
                    ? [childId, spouseId]
                    : [spouseId];
                })
              : [childId])
          );
        })
      );
    });
    return group;
  };

  const getGrandchildrenGroups = (personId) => {
    const spouses = api.getSpouses(personId);
    const groups = [];
    spouses.forEach((spouseId, index) => {
      const children = api.getChildren(spouseId, personId);
      children.forEach((childId) => {
        const spouses = api.getSpouses(childId);
        groups.push(
          [].concat(
            ...spouses.map((spouseId) => {
              return api.getChildren(spouseId, childId);
            })
          )
        );
      });
    });
    return groups;
  };

  return { matrix: matrix, relatedMatrices: relatedMatrices };
};

export default useNodeMatrix;
