import React, { useState, useEffect, useRef } from "react";

import { useApi } from "../../contexts/ApiContext";
import "./PersonDetailsForm.css";

/**
 * Form component for editing a given person's name, date of birth, and amount of
 * shared DNA.
 *
 * @param {{personId: number, onChange: Function, onSubmit: Function}} props component properties object
 * @param props.personId id of the person to edit
 * @param props.onChange callback for when details change, with signature (details: {name: string, dateOfBirth:string, sharedDna:number})
 * @param props.onSubmit callback for enter key pressed
 */
const PersonDetailsForm = ({ personId, onChange, onSubmit }) => {
  const api = useApi();
  const nameInput = useRef(null);
  const updateTimer = useRef(null);
  const [name, setName] = useState(() => api.getName(personId) ?? "");
  const [dateOfBirth, setDateOfBirth] = useState(() => api.getDateOfBirth(personId) ?? "");
  const [sharedDna, setSharedDna] = useState(() => api.getSharedDna(personId) ?? "");

  useEffect(() => {
    setName(api.getName(personId) ?? "");
    setDateOfBirth(api.getDateOfBirth(personId) ?? "");
    setSharedDna(api.getSharedDna(personId) ?? "");
    nameInput.current.focus();
  }, [personId]);

  const nameChanged = (newValue) => {
    setName(newValue);
    clearTimeout(updateTimer.current);
    updateTimer.current = setTimeout(() => {
      if (onChange) {
        onChange({
          name: newValue,
          dateOfBirth: dateOfBirth,
          sharedDna: sharedDna,
        });
      }
    }, 300);
  };

  const dateOfBirthChanged = (newValue) => {
    setDateOfBirth(newValue);
    clearTimeout(updateTimer.current);
    updateTimer.current = setTimeout(() => {
      if (onChange)
        onChange({
          name: name,
          dateOfBirth: newValue,
          sharedDna: sharedDna,
        });
    }, 300);
  };

  const sharedDnaChanged = (newValue) => {
    setSharedDna(newValue);
    clearTimeout(updateTimer.current);
    updateTimer.current = setTimeout(() => {
      if (onChange)
        onChange({
          name: name,
          dateOfBirth: dateOfBirth,
          sharedDna: newValue,
        });
    }, 300);
  };

  if (!personId) return null;

  return (
    <form
      className="person-details"
      onKeyPress={(e) => {
        if (e.key == "Enter" && onSubmit) {
          onSubmit();
        }
      }}
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <input
        type="text"
        placeholder="Name"
        name="name"
        value={name}
        onChange={(e) => nameChanged(e.target.value)}
        ref={nameInput}
      />
      <input
        type="text"
        placeholder="Date of Birth"
        name="dateOfBirth"
        value={dateOfBirth}
        onChange={(e) => dateOfBirthChanged(e.target.value)}
      />
      <input
        type="text"
        placeholder="Amount of DNA Shared (cM)"
        name="sharedDna"
        value={sharedDna != 0 ? sharedDna : ""}
        onChange={(e) => sharedDnaChanged(e.target.value)}
      />
    </form>
  );
};

export default PersonDetailsForm;
