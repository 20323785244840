import React from "react";

import "./DnaAnalysisResultStrength.css";

/**
 * A meter component that visually represents a given strength value.
 *
 * @param {{strength: number}} props component properties object
 * @param props.strength number representing strength of scenario (out of 1)
 */
const DnaAnalysisResultStrength = ({ strength }) => {
  return (
    <div className="dna-analysis-result-strength" title={strength * 100}>
      <div
        className="dna-analysis-result-strength-meter"
        data-testid="dna-analysis-result-strength-meter"
        style={{ width: strength * 100 + "%" }}
      ></div>
    </div>
  );
};

export default DnaAnalysisResultStrength;
